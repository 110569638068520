import cx from 'clsx';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import MobileTabs from 'src/components/MobileTabs';
import PlanCard from 'src/components/PlanCard';
import IconButton from 'src/components/core/IconButton';
import Typography from 'src/components/core/Typography';
import { useSwipable } from 'src/hooks';
import SectionContainer from 'src/sections/shared/SectionContainer';
import { pxToRem, spacing, useIsMobile } from 'src/theme';
import { PlanDetails } from 'src/types';
import useResizeObserver from 'use-resize-observer';

const mobileSliderWidth = '250vw';

const useStyles = createUseStyles({
  container: {
    overflow: 'visible',
    // CSS trick so that we can have a video which is not absolute positioned
    // and still overlap with the above section
    paddingTop: '1px !important',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  middle: {
    marginLeft: spacing(8),
    marginRight: spacing(8),
  },
});

const plans: PlanDetails[] = [
  {
    title: 'Starter Plan',
    recurringCostCents: 3000,
    features: [
      'For ad budgets up to $150 per month, this plan is great for businesses looking to generate a modest boost in sales.',
      'Your monthly cost will be your set budget + the $30 management fee. ',
    ],
    planIds: ['starter'],
    hidden: false,
  },
  {
    title: 'Growth Plan',
    recurringCostCents: 5000,
    features: [
      'For ad budgets up to $600 per month, this plan is ideal for businesses looking to grow steadily with a reliable stream of paying customers.',
      'Your monthly cost will be your set budget + the $50 management fee.',
    ],
    planIds: ['growth'],
    hidden: false,
  },
  {
    title: 'Accelerate Plan',
    recurringCostCents: 8000,
    features: [
      'For ad budgets exceeding $600 per month, experience unprecedented growth in sales! This plan is perfect for both established businesses looking to scale and new ventures aiming to build strong initial customer traction.',
      'Your monthly cost will be your set budget + the $80 management fee.',
    ],
    planIds: ['accelerate'],
    hidden: false,
  },
];

const Pricing: React.FC = () => {
  const classes = useStyles();
  const [activeIdx, setActiveIdx] = useState(1);
  const {
    height: eliteDesktopHeight = 0,
    ref: eliteDesktopRef,
  } = useResizeObserver<HTMLDivElement>();
  const { width: cardWidth = 0, ref: cardRef } = useResizeObserver<HTMLDivElement>();

  const {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleTouchCancel,
  } = useSwipable({
    onSwipeLeft: useCallback(() => {
      setActiveIdx((old) => Math.max(0, old - 1));
    }, []),
    onSwipeRight: useCallback(() => {
      setActiveIdx((old) => Math.min(plans.length - 1, old + 1));
    }, []),
  });

  const isMobile = useIsMobile();

  return (
    <SectionContainer background="light-blue">
      <Typography
        variant="h2"
        color="blue"
        centered
        style={{ maxWidth: pxToRem(870), marginInline: 'auto' }}
      >
        Simple. Fair. Pricing
      </Typography>
      <Typography
        variant="p1"
        color="blue"
        centered
        underline={!isMobile}
        style={{
          maxWidth: pxToRem(600),
          marginInline: 'auto',
          marginBlock: spacing(8),
        }}
      >
        Pick your own price and change your ads budget anytime. No setup fees,
        hidden fees, or annual contracts.
      </Typography>

      <div
        className="hidden overflow-hidden md:flex items-end justify-center"
        style={{
          paddingBlock: spacing(12),
        }}
      >
        {plans.map((data, i) => (
          <PlanCard
            bulletStyle="none"
            recurringPriceSubtitle="Management Fees Per Month"
            allowSelection
            key={data.planIds[0]}
            data={data}
            popular={i === 1}
            hat={i === 1 ? 'Popular' : undefined}
            ref={i === plans.length - 1 ? eliteDesktopRef : null}
            className={cx(i === 1 && classes.middle)}
            cardStyle={{
              height: i === plans.length - 1 ? undefined : eliteDesktopHeight,
            }}
          />
        ))}
      </div>
      <div className="block md:hidden overflow-hidden">
        <div className="px-4">
          <MobileTabs
            options={plans.map((d) => d.title.replace(/\splan/i, ''))}
            activeIdx={activeIdx}
            onChange={(i) => setActiveIdx(i)}
          />
        </div>
        <div
          className="flex items-stretch justify-start mt-4"
          style={{
            width: mobileSliderWidth,
            transform: `translate(calc(50vw - (${cardWidth}px * ${activeIdx}) - ${pxToRem(
              cardWidth / 2,
            )} - ${spacing(activeIdx * 8)}))`,
            transitionDuration: '0.5s',
          }}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchCancel}
        >
          {plans.map((data, i) => (
            <PlanCard
              bulletStyle="none"
              recurringPriceSubtitle="Management Fees Per Month"
              allowSelection
              key={data.planIds[0]}
              data={data}
              ref={i === 1 ? cardRef : null}
              popular={i === 1}
              className={cx(i === 1 && 'mx-8')}
              cardStyle={{
                height: '100%',
              }}
            />
          ))}
        </div>
        <div className="mt-6 mb-6 flex justify-center">
          {Array.from({ length: plans.length }, (_, i) => (
            <IconButton key={i} onClick={() => setActiveIdx(i)}>
              <div
                className={cx([
                  activeIdx === i ? 'bg-teal' : 'bg-blue',
                  'rounded-[50%]',
                  'w-3',
                  'h-3',
                ])}
              />
            </IconButton>
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};

export default Pricing;
