import { CheckIcon } from '@heroicons/react/solid';
import React from 'react';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';
import theme, { pxToRem, spacing } from 'src/theme';
import { PlanDetails } from 'src/types';

interface Props {
  data: PlanDetails,
  popular?: boolean,
  allowSelection?: boolean,
  hasCoupon?: boolean,
  showCouponApplied?: boolean,
  hat?: string,
  bulletStyle?: 'checks' | 'none',
  recurringPriceSubtitle?: string,
  cardStyle?: React.CSSProperties,
  className?: string,
}

const PlanCard = React.forwardRef<HTMLDivElement, Props>(({
  data,
  popular = false,
  allowSelection = false,
  hasCoupon = false,
  showCouponApplied = false,
  hat,
  bulletStyle = 'checks',
  recurringPriceSubtitle,
  cardStyle,
  className,
}, ref) => (
  <div
    ref={ref}
    style={{
      width: '100%',
      maxWidth: pxToRem(410),
    }}
    className={className}
  >
    {hat && (
      <div className="hidden md:flex w-[80%] mx-auto bg-orange align-center justify-center rounded-t-[20px] py-2">
        <Typography variant="p2" color="blackish" style={{ fontWeight: 600 }}>
          {hat}
        </Typography>
      </div>
    )}
    <div
      className="border-10 py-12 px-12"
      style={{
        width: '100%',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 0 2px 16px rgba(0, 0, 0, 0.1)',
        borderRadius: 20,
        background: popular
          ? 'linear-gradient(109deg, #13266D 0.49%, #00B6B6 130.79%), #4282FF'
          : 'white',
        ...cardStyle,
      }}
    >
      <Typography
        variant="h3"
        color={popular ? 'white' : 'blue'}
        centered
        className="uppercase mb-2"
      >
        {data.title}
      </Typography>
      {
        hasCoupon && !showCouponApplied
          ? (
            <div
              className="bg-gray-skeleton rounded-md mx-auto mb-3"
              style={{ width: pxToRem(191), height: pxToRem(46) }}
            />
          )
          : (
            <Typography
              variant="h2"
              color={popular ? 'white' : 'blue'}
              centered
              style={{ fontWeight: 600 }}
              className="mb-3 uppercase"
            >
              {data.recurringCostCents === 0 ? 'Free' : `$${data.recurringCostCents / 100}`}
            </Typography>
          )
      }
      {showCouponApplied && (
        <Typography
          variant="subtitle1"
          centered
          className="mb-2"
          style={{ fontWeight: 600, color: '#2A499B' }}
        >
          Congrats! Coupon Applied
        </Typography>
      )}
      <Typography
        variant="p3"
        // eslint-disable-next-line no-nested-ternary
        color={popular ? 'white' : allowSelection ? 'teal' : 'blue'}
        centered
        style={{ fontWeight: 600 }}
      >
        {data.recurringCostCents === 0 ? 'Free Forever!' : recurringPriceSubtitle || 'Monthly'}
      </Typography>
      {allowSelection ? (
        <Button
          variant={popular ? 'filled' : 'outlined'}
          color="teal"
          href={`/checkout?planId=${encodeURIComponent(data.planIds[0])
            }&redirect=${data.planIds[0] === 'free'
              ? encodeURIComponent(`/dashboard?planId=${data.planIds[0]}`)
              : encodeURIComponent(`/dashboard/settings/billing?planId=${data.planIds[0]}`)
            }`}
          className="w-full my-7"
          style={{
            borderWidth: 1,
            borderRadius: 6,
            paddingTop: spacing(3),
            paddingBottom: spacing(3),
          }}
        >
          Select Plan
        </Button>
      ) : (
        <hr className="border border-1 border-teal my-10" />
      )}
      {data.features.map((benefit) => (
        <div className="flex mb-3" key={benefit}>
          {bulletStyle === 'checks' && (
            <CheckIcon
              className="text-teal mr-4"
              style={{
                width: pxToRem(24),
                height: pxToRem(24),
                minWidth: pxToRem(24),
                minHeight: pxToRem(24),
                color: popular ? '#9AFD4D' : theme.palette.primary.TEAL,
              }}
            />
          )}
          <Typography variant="p3" color={popular ? 'white' : 'blue'} style={{ fontWeight: 600 }}>
            {benefit}
          </Typography>
        </div>
      ))}
    </div>
  </div>
));

export default PlanCard;
